import React from "react"
import {useGetPermissionsQuery} from "./permissionsApi"
import ErrorBadGateway from "components/ErrorBadGateway"
import LoadingBlock from "../../../components/LoadingBlock"
import {useGetSelfQuery} from "../../employee/employeeApi"
import Forbidden from "../../../components/Forbidden"

interface PermissionProviderProps {
  children?: React.ReactNode
}

const PermissionProvider: React.FC<PermissionProviderProps> = ({children}) => {
  const {data: permission, isLoading, isError} = useGetPermissionsQuery()
  const {data: employeeSelf} = useGetSelfQuery()
  if (isLoading) return <LoadingBlock />
  if (isError) return <ErrorBadGateway />

  if (!permission?.data?.can_use_system && !employeeSelf?.data?.branches?.length) {
    return <Forbidden />
  }
  return <>{children}</>
}

export default PermissionProvider
