import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseKpi} from "../../types/ResponseKpi"

interface ArgsProps {
  employee_id?: number
  date_time_from?: string
  date_time_to?: string
}

export const fetchKpi = createAsyncThunk<ResponseKpi, ArgsProps, AppThunkProps>(
  "kpi/fetch",
  async (data, {signal, getState}) => {
    let params = ""
    if (data.employee_id) params += `&employee_id=${data.employee_id}`
    if (data.date_time_from) params += `&date_time_from=${data.date_time_from}`
    if (data.date_time_to) params += `&date_time_to=${data.date_time_to}`
    // Получения данных авторизации
    const {auth} = getState()
    // Запрос
    const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/statistics/kpi-operator?${params}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
      },
      signal
    })
    return await response.json()
  }
)
