import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponseOrders} from "types/ResponseOrders"

export const orderTableApi = createApi({
  reducerPath: "orderTableApi",
  baseQuery: apiReviewQuery,
  tagTypes: ["orders"],
  endpoints: build => ({
    postOrdersCall: build.query<null, Partial<{phone_number: string, order_id: string}>>({
      query: data => ({
        url: `orders/call`,
        method: "POST",
        body: {
          phone_number: data.phone_number,
          order_id: data.order_id
        }
      }),
      providesTags: ["orders"]
    }),
    updateOrderStatus: build.mutation<
      ResponseOrders,
      Partial<{order_id: string, branch_id: string, is_positive: boolean}>
    >({
      query: data => ({
        url: `orders`,
        method: "PATCH",
        body: data
      }),
      invalidatesTags: ["orders"]
    }),
    updateIIKOStatus: build.mutation<ResponseOrders, Partial<{order_id: string}>>({
      query: data => ({
        url: `orders?order_id=${data.order_id}`,
        method: "PUT"
      }),
      invalidatesTags: ["orders"]
    }),
    changeOrderStatus: build.mutation<void, Partial<{order_id: string, resolution: string}>>({
      query: data => ({
        url: `orders/resolution`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["orders"]
    })
  })
})

export const {
  useLazyPostOrdersCallQuery,
  useUpdateOrderStatusMutation,
  useUpdateIIKOStatusMutation,
  useChangeOrderStatusMutation
} = orderTableApi
