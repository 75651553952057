import {PayloadAction, createEntityAdapter, createSlice} from "@reduxjs/toolkit"
import {fetchOrders} from "./fetchOrders"
import {ResponseOrders} from "types/ResponseOrders"
import {StoreState} from "store"
import {useSelector} from "react-redux"

export const orderTableAdapter = createEntityAdapter<ResponseOrders["data"]["content"][0]>()

export interface StateProps {
  loading: boolean
  total: number
  selected_status: string
}

const initialState = orderTableAdapter.getInitialState<StateProps>({
  loading: false,
  total: 0,
  selected_status: "NEW"
})

const orderTableSlice = createSlice({
  name: "order-table",
  initialState,
  reducers: {
    updateOrder: (state, action: PayloadAction<ResponseOrders["data"]["content"][0]>) => {

      if (action.payload.process_status_id === state.selected_status) {
        orderTableAdapter.upsertOne(state, action.payload)
      } else {
        orderTableAdapter.removeOne(state, action.payload.id)
      }
    },
    updateSelectedStatusOrderId: (state, action: PayloadAction<StateProps["selected_status"]>) => {
      state.selected_status = action.payload
    }
  },
  extraReducers: builder => {
    // Получить список отзывов
    builder.addCase(fetchOrders.pending, state => {
      // Начать загрузку
      state.loading = true
    })
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      if (action.payload && action.payload.success) {
        // Изменить список отзывов
        orderTableAdapter.setAll(state, action.payload.data.content)
        // Изменить общее кол-во отзывов
        state.total = action.payload.data.total_elements
      } else {
        // Удалить все отзывы
        orderTableAdapter.removeAll(state)
        // Общее кол-во изменить на 0
        state.total = 0
      }
      // Остановить загрузку
      state.loading = false
    })
    builder.addCase(fetchOrders.rejected, state => {
      // Удалить все отзывы
      orderTableAdapter.removeAll(state)
      // Общее кол-во изменить на 0
      state.total = 0
      // Остановить загрузку
      state.loading = false
    })
  }
})

export default orderTableSlice.reducer
//Actions
export const {updateOrder, updateSelectedStatusOrderId} = orderTableSlice.actions
// Методы для хуков
const {selectAll} = orderTableAdapter.getSelectors<StoreState>(state => state.orderTable)
// Вывод всех отзывов
export const useGetOrders = () => useSelector(selectAll)
// Вывод общее кол-во
export const useGetOrderTableTotal = () => useSelector((state: StoreState) => state.orderTable.total)
// Вывод загрузки
export const useGetOrderTableLoading = () => useSelector((state: StoreState) => state.orderTable.loading)
