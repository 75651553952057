import {configureStore, combineReducers} from "@reduxjs/toolkit"
import {useDispatch as useStoreDispatch} from "react-redux"
import feedbackTable from "features/feedback-table/feedbackTableSlice"
import orderTable from "features/order-table/orderTableSlice"
import discussion from "features/feedback/discussion/descussionSlice"
import mention from "features/feedback/mentions/mentionsSlice"
import sourceStatistic from "./features/source-statistic/sourceStatisticSlice"
import kpi from "features/kpi/kpiSlice"
import ratingByCategoryType from "./features/rating-statistic/ratingByCategoryTypeSlice"
import categoryStatistic from "./features/category-statistic/categoryStatisticSlice"
import auth from "features/auth/authSlice"
import {statusApi} from "./features/status/statusApi"
import {sourceApi} from "./features/source/sourceApi"
import {feedbackApi} from "./features/feedback/feedbackApi"
import {branchApi} from "./features/branch/branchApi"
import {orderPhoneApi} from "./features/order/orderPhoneApi"
import {employeeSessionsApi} from "./features/employee-sessions/employeeSessionsApi"
import {feedbackProcessingTimeApi} from "./features/configurations/feedback-processing-time/feedbackProcessingTimeApi"
import {resolutionCategoryTypes} from "./features/categories/categoriesApi"
import {employeeApi} from "./features/employee/employeeApi"
import {feedbackPermissionApi} from "./features/configurations/permissions/permissionsApi"
import {ratingCategoryTypeApi} from "./features/settings/rating-category/ratingCategoryTypeApi"
import {ratingCategoryApi} from "./features/settings/rating-category/ratingCategoryApi"
import {sourceTypeApi} from "./features/settings/source-type/sourceTypeApi"
import {orderTypeApi} from "./features/settings/order-type/orderTypeApi"
import {orderSourceTypeApi} from "./features/settings/order-source-type/orderSourceTypeApi"
import {resolutionCategoryApi} from "./features/settings/resolution-category/resolutionCategoryApi"
import {resolutionCategoryTypeApi} from "./features/settings/resolution-category/resolutionCategoryTypeApi"
import {kpiFeedbackApi} from "./features/kpi-feedback/kpiFeedbackApi"
import {ratingFeedbackApi} from "./features/rating-statistic/ratingFeedbackApi"
import {categoryFeedbackApi} from "./features/category-statistic/categoryFeedbackApi"
import {dashboardApi} from "./features/dashboard/dashboardApi"
import {discussionApi} from "./features/feedback/discussion/discussionApi"
import {orderTableApi} from "./features/order-table/orderTableApi"
import { ordersStatusApi } from "features/order-table/ordersStatusApi"
import {orderMoreApi} from "features/orderInfo/orderMoreApi"
import {customerApi} from "./features/customer/customerApi"
import {systemLanguageType} from "features/settings/system-language/systemLanguageApi"
// List reducers
export const reducer = combineReducers({
  [statusApi.reducerPath]: statusApi.reducer,
  [orderMoreApi.reducerPath]: orderMoreApi.reducer,
  [sourceApi.reducerPath]: sourceApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [branchApi.reducerPath]: branchApi.reducer,
  [orderPhoneApi.reducerPath]: orderPhoneApi.reducer,
  [employeeSessionsApi.reducerPath]: employeeSessionsApi.reducer,
  [feedbackProcessingTimeApi.reducerPath]: feedbackProcessingTimeApi.reducer,
  [resolutionCategoryTypes.reducerPath]: resolutionCategoryTypes.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [feedbackPermissionApi.reducerPath]: feedbackPermissionApi.reducer,
  [ratingCategoryTypeApi.reducerPath]: ratingCategoryTypeApi.reducer,
  [ratingCategoryApi.reducerPath]: ratingCategoryApi.reducer,
  [sourceTypeApi.reducerPath]: sourceTypeApi.reducer,
  [orderTypeApi.reducerPath]: orderTypeApi.reducer,
  [orderSourceTypeApi.reducerPath]: orderSourceTypeApi.reducer,
  [resolutionCategoryApi.reducerPath]: resolutionCategoryApi.reducer,
  [resolutionCategoryTypeApi.reducerPath]: resolutionCategoryTypeApi.reducer,
  [kpiFeedbackApi.reducerPath]: kpiFeedbackApi.reducer,
  [ratingFeedbackApi.reducerPath]: ratingFeedbackApi.reducer,
  [categoryFeedbackApi.reducerPath]: categoryFeedbackApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [discussionApi.reducerPath]: discussionApi.reducer,
  [orderTableApi.reducerPath]: orderTableApi.reducer,
  [ordersStatusApi.reducerPath]: ordersStatusApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [systemLanguageType.reducerPath]: systemLanguageType.reducer,
  feedbackTable,
  orderTable,
  discussion,
  auth,
  kpi,
  sourceStatistic,
  ratingByCategoryType,
  categoryStatistic,
  mention
})

export type StoreState = ReturnType<typeof reducer>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch

export interface AppThunkProps {
  dispatch: AppDispatch;
  state: StoreState;
  extra?: unknown;
  rejectValue?: unknown;
}

export const useDispatch = () => useStoreDispatch<AppDispatch>()
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({immutableCheck: false})
      .concat(feedbackApi.middleware)
      .concat(statusApi.middleware)
      .concat(sourceApi.middleware)
      .concat(branchApi.middleware)
      .concat(orderPhoneApi.middleware)
      .concat(employeeSessionsApi.middleware)
      .concat(feedbackProcessingTimeApi.middleware)
      .concat(resolutionCategoryTypes.middleware)
      .concat(systemLanguageType.middleware)
      .concat(employeeApi.middleware)
      .concat(feedbackPermissionApi.middleware)
      .concat(ratingCategoryTypeApi.middleware)
      .concat(ratingCategoryApi.middleware)
      .concat(sourceTypeApi.middleware)
      .concat(orderTypeApi.middleware)
      .concat(orderSourceTypeApi.middleware)
      .concat(resolutionCategoryApi.middleware)
      .concat(resolutionCategoryTypeApi.middleware)
      .concat(kpiFeedbackApi.middleware)
      .concat(ratingFeedbackApi.middleware)
      .concat(categoryFeedbackApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(discussionApi.middleware)
      .concat(orderTableApi.middleware)
      .concat(ordersStatusApi.middleware)
      .concat(orderMoreApi.middleware)
      .concat(customerApi.middleware)
      .concat(systemLanguageType.middleware)
})
