import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {ResponsePhoneUrl} from "../../types/ResponsePhoneUrl"
import RepsponseOrderDetail from "types/ResponseOrderDetail"
import {Audio} from "types/FeedbackMore"
import {OrderCategories} from "types/ResponseOrder"

export const orderMoreApi = createApi({
  reducerPath: "orderMoreApi",
  baseQuery: apiReviewQuery,
  tagTypes: ["orderMore"],
  endpoints: builder => ({
    getAudioById: builder.mutation<Audio, string>({
      query: id => `orders/call/${id}`,
      invalidatesTags: ["orderMore"]
    }),
    getOrderById: builder.query<RepsponseOrderDetail, Partial<string | undefined>>({
      query: id => `orders/${id}/details`,
      providesTags: ["orderMore"]
    }),
    getPhoneUrl: builder.query<ResponsePhoneUrl, {phone_number: string, order_id: string}>({
      query: data => ({
        url: `/orders/call`,
        method: "POST",
        body: {
          order_id: data.order_id,
          phone_number: data.phone_number
        }
      })
    }),
    getOrdersCategories: builder.query<OrderCategories, void>({
      query: data => ({
        url: "/orders/categories",
        method: "GET",
        body: data
      }),
      providesTags: ["orderMore"]
    }),
    delayOrder: builder.mutation<{success: boolean}, string>({
      query: orderId => ({
        url: `orders/delay/${orderId}`,
        method: "PATCH"
      }),
      invalidatesTags: ["orderMore"]
    }),
    getOrdersResolution: builder.query<OrderCategories, void>({
      query: data => ({
        url: "/orders/resolutions",
        method: "GET",
        body: data
      }),
      providesTags: ["orderMore"]
    })
  })
})

export const {
  useGetOrderByIdQuery,
  useLazyGetPhoneUrlQuery,
  useGetAudioByIdMutation,
  useGetOrdersCategoriesQuery,
  useDelayOrderMutation,
  useGetOrdersResolutionQuery
} = orderMoreApi
