import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "utils/api-config"
import {SystemLanguageType} from "types/SystemLanguage.Type"

export const systemLanguageType = createApi({
  reducerPath: "systemLanguageType",
  baseQuery: apiReviewQuery,
  tagTypes: ["systemLanguageType"],
  endpoints: build => ({
    getSystemLanguageTypes: build.query<SystemLanguageType, void>({
      query: () => ({
        url: "/system/languages",
        method: "GET"
      })
    })
  })
})

export const {useGetSystemLanguageTypesQuery} = systemLanguageType
