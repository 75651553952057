import {createAsyncThunk} from "@reduxjs/toolkit"
import {AppThunkProps} from "store"
import {ResponseDiscussion} from "types/ResponseDiscussion"

export const fetchMentions = createAsyncThunk<ResponseDiscussion, undefined, AppThunkProps>(
  "discussion/fetchMentions",
  async (params, {getState, signal}) => {
    // Получения данных авторизации
    const {auth} = getState()
    // Запрос
    const response = await fetch(`${process.env.REACT_APP_API_REVIEW}/discussions/self`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
        "X-Time-Zone": process.env.REACT_APP_TIME_ZONE ?? "UTC"
      },
      signal
    })
    return await response.json()
  }
)
