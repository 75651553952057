import {createApi} from "@reduxjs/toolkit/query/react"
import {apiReviewQuery} from "../../utils/api-config"
import {ResponseCustomerCallInfo} from "../../types/ResponseCustomerCallInfo"
import { CustomerCoupon } from "types/ResponseCustomerCoupon"
import { CustomerPointsResponse } from "types/CustomerPointsResponse"

export const customerApi = createApi({
    reducerPath: "customerApi",
    baseQuery: apiReviewQuery,
    tagTypes: ["Customer"],
    endpoints: builder => ({
        getCustomer: builder.query<ResponseCustomerCallInfo, string | undefined>({
            query: (phone_number: string) => `customers?customer_phone=${phone_number}`,
            providesTags: ["Customer"]
        }),
        getCustomerCoupon: builder.query<{
            success: boolean,
            data: CustomerCoupon[]
        }, string | undefined>({
            query: (customer_phone: string) => `customers/${customer_phone}/coupons`,
            providesTags: ["Customer"]
        }),
        postCustomerCoupon: builder.mutation<null, {coupon_id: number}>({
            query: ({coupon_id}) => ({
                url: `/customers/coupons?coupon_id=${coupon_id}`,
                method: "POST"
            })
        }),
        getCustomerPoints: builder.query<{
            success: boolean,
            data: CustomerPointsResponse
        }, string | undefined>({
            query: (customer_phone: string) =>
                `customers/${customer_phone}/loyalty-points`,
            providesTags: ["Customer"]
        }) 
    })
})

export const {useGetCustomerQuery,useLazyGetCustomerCouponQuery, usePostCustomerCouponMutation, useLazyGetCustomerPointsQuery} = customerApi
